.bradcam_bg_1{
    background-image: url(../img/banner/bradcam.png);
}
.bradcam_bg_2{
    background-image: url(../img/banner/bradcam2.png);
}
.bradcam_bg_3{
    background-image: url(../img/banner/bradcam3.png);
}
.bradcam_area{
    
    background-size: cover;
    background-position: center center;
    padding: 220px 0 194px 0;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
    @media #{$tablet_device} {
        padding: 120px 0;
    }
    &::before{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#001d38+0,001d38+100&1+0,0.6+100 */
        background: -moz-linear-gradient(top,  rgba(0,29,56,1) 0%, rgba(0,29,56,0.6) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,29,56,1) 0%,rgba(0,29,56,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,29,56,1) 0%,rgba(0,29,56,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001d38', endColorstr='#99001d38',GradientType=0 ); /* IE6-9 */
        z-index: -1;
        content: '';
    }
    @media #{$mobile_device} {
        padding: 150px 0;
    }
    h3{
        font-size:70px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 0;
        text-transform: capitalize;
        @media #{$mobile_device} {
            font-size: 30px;
        }
    }
    p{
        font-size: 18px;
        color: #fff;
        font-weight: 400;
        text-transform: capitalize;
        a{
            color: #fff;
            &:hover{
                color: #fff;
            }
        }
    }

    .icon{
        background: #FF5748;
        width: 136px;
        height: 136px;
        @include border-radius(50%);
        text-align: center;
        line-height: 136px;
        margin: auto;
        i{
            color: #fff;
            font-size: 68px;

        }
    }
    h4{
        font-size: 70px; 
        font-weight: 300;
        color: #fff;
        margin: 10px 0;
        @media #{$mobile_device} {
            font-size: 40px;
        }
        @media #{$tablet_device} {
            font-size: 40px;
        }
    }
    p{
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        font-family: $font2;
    }
}
