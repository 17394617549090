.slider_bg_1{
    background-image: url(../img/banner/banner.png);
}
.slider_bg_2{
    background-image: url(../img/banner/banner_2.png);
}
.slider_area{
    position: relative;
    z-index: 0;
    &::before{
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#001d38+0,001d38+100&1+0,0.6+100 */
background: -moz-linear-gradient(left,  rgba(0,29,56,1) 0%, rgba(0,29,56,0.6) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(0,29,56,1) 0%,rgba(0,29,56,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(0,29,56,1) 0%,rgba(0,29,56,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001d38', endColorstr='#99001d38',GradientType=1 ); /* IE6-9 */

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        // opacity: .6;
    }
    .single_slider{
        height: 900px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        &.single_listing{
            height: 500px;
        }
        @media #{$mobile_device} {
            height: auto;
        }
        @media #{$tablet_device} {
            height: 600px;
        }
        .slider_text{
            @media #{$mobile_device}{
                margin-top: 90px;
                margin-bottom: 20px;
            }
            h3{
            font-family: $font1;
            font-size: 70px;
            text-transform: capitalize;
            letter-spacing: 2px;
            font-weight: 300;
            line-height: 70px;
            margin-bottom: 70px;
            color: #fff;
             span{
                 font-weight: 700;
             }
             @media #{$mobile_device} {
               font-size: 30px;
            //    letter-spacing: 3px;
            margin-bottom: 30px;
            line-height: 45px;
            }
             @media #{$tablet_device} {
                font-size: 54px;
            }
             @media #{$mid_device} {
               font-size: 35px;
            }
            }
            p{
                font-size: 30px;
                font-weight: 400;
                color: #F91842;
                // margin-bottom: 24px;
                // margin-top: 10px;
                font-family: $font2;
                margin-bottom: 24px;
             @media #{$mid_device} {
                font-size: 16px;
             }
             @media #{$mobile_device} {
                font-size: 16px;
             }
            }
            .search_form{
                background: #fff;
                padding: 11px 10px;
                @include border-radius(5px);
                .input_field{
                    input{
                        width: 100%;
                        height: 30px;
                        border: none;
                        padding: 15px;
                        font-family: $font2;
                        @media #{$mobile_device}{
                            margin-bottom: 20px;
                        }
                        &:focus{
                            outline: none;
                        }
                        &::placeholder{
                            font-size: 16px;
                            font-weight: 400;
                            color: #727272;
                            font-family: $font2;
                        }
                    }
                    &.location{
                        position: relative;
                        input{
                            padding-left: 45px;
                            border-left: 1px solid #EAEAEA;

                            @media #{$mobile_device} {
                                border-left: 1px solid transparent ;
                                padding-left: 15px;
                            }
                        }

                        &:before{
                            position: absolute;
                            content: '\f041';
                            font-family: fontawesome;
                            font-size: 19px;
                            color: #919191;
                            top: 50%;
                            @include transform(translateY(-50%));
                            left: 25px;
                            @media #{$mobile_device} {
                                display: none;
                            }
                        }
                    }
                }
                button{
                }
            }
            .button_search{
                text-align: right;
                @media #{$mobile_device} {
                    text-align: left;
                }
            }
            .quality{
                margin-top: 40px;
                @media #{$mobile_device} {
                    margin-top: 20px;
                }
                ul{
                    li{
                        display: inline-block;
                        @media #{$mobile_device}{
                            margin-bottom: 10px;
                        }
                        button{
                            border: none;
                            background: rgba(255,255,255,.20) ;
                            border-radius: 5px;
                            font-size: 14px;
                            color :#fff;
                            font-weight: #000;
                            font-family: $font2;
                            padding: 5px 19px;
                            display: inline-block;
                            margin: 0 3px;
                            @include transition(.3s);
                            cursor: pointer;
                            &:hover{
                                background: #F91842;
                            }
                        }
                    }
                }
            }
        }
    } 
    .serach_icon a {
        width: 45px;
        height: 45px;
        background: #FD8E5E;
        text-align: center;
        line-height: 45px;
        color: #fff;
        border-radius: 5px;
        display: inline-block;
        @media #{$mobile_device} {
            width: 100%;
            margin-bottom: 15px;
        }
        @media #{$tablet_device} {
            width: 50%;
         }
    }
    .serach_icon {
        @include flexbox();
        @include align-items(end);
        @media #{$mobile_device} {
            display: block;
        }
        @media #{$tablet_device} {
           width: 50%;
            align-items: center;
            margin-top: 12px;
        }
    }
    .single-field{
        @media #{$tablet_device} {
            margin-bottom: 20px;
        }
        &.range_slider{
            width: 100%;
            #slider{
                margin: 0;
                
            }
        }

        .nice-select .list {
            color: #000;
            border-radius: 0;
        }
        &.max_width{
            margin-right: 24px;
            @media #{$mobile_device} {
                margin-right: 0;
            }
            label{
                display: block;
                font-size: 15px;
                color: #FDAE5C;
                
            }
            .nice-select.wide {
                width: 166px;
                background: transparent;
                color: #C7C7C7;
                font-size: 15px;
                font-weight: 400;
                border: 1px solid rgba(255,255,255,.40);
                height: 45px;
                line-height: 45px;
                @media #{$mobile_device} {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
            .nice-select::after {
                content: "\e64b";
                display: block;
                height: 5px;
                margin-top: -5px;
                pointer-events: none;
                position: absolute;
                right: 30px;
                top: 8px;
                transition: all 0.15s ease-in-out;
                width: 5px;
                font-family: 'themify';
                color: #ddd;
                color: #FFFFFF;
                top: 5px;
                font-size: 12px;
            }
        }
        &.min_width{
            margin-right: 24px;
            @media #{$mobile_device} {
                margin-right: 0;
                margin-bottom: 20px;
            }
            label{
                display: block;
                font-size: 15px;
                color: #FDAE5C;
                
            }
            .nice-select.wide {
                width: 110px;
                background: transparent;
                color: #C7C7C7;
                font-size: 15px;
                font-weight: 400;
                border: 1px solid rgba(255,255,255,.40);
                height: 45px;
                line-height: 45px;
                @media #{$mobile_device} {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
            .nice-select::after {
                content: "\e64b";
                display: block;
                height: 5px;
                margin-top: -5px;
                pointer-events: none;
                position: absolute;
                right: 30px;
                top: 8px;
                transition: all 0.15s ease-in-out;
                width: 5px;
                font-family: 'themify';
                color: #ddd;
                color: #FFFFFF;
                top: 5px;
                font-size: 12px;
            }
        }
    }
}
.property_bg{
    background-image: url(../img/banner/property.png);
}
