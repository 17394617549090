
.about_info_area{
    padding-bottom: 70px;
    &.plus_padding{
        padding-top: 100px;
        @media #{$mobile_device} {
            padding-top: 30px;
        }
    }
    .about_text{
        margin-bottom: 30px;
        h3{
            font-size: 46px;
            font-weight:300 ;
            color: #001D38;
            @media #{$mobile_device} {
                font-size: 30px;
            }
        }
        p{
            font-size: 15px;
            font-weight: 400;
            color: #727272;
            margin-bottom: 24px;
            margin-top: 10px;
        }
        a{
            padding: 13px 43px;
        }
    }
    .about_thumb{
        @include border-radius(10px);
        overflow: hidden;
        margin-bottom: 30px;
        img{
            width: 100%;

        }
    }
}

// about_mission 
.about_mission{
    padding-top: 150px;
    @media #{$mobile_device} {
        padding-top: 50px;
    }
    @media #{$tablet_device} {
        padding-top: 80px;
    }
    .about_thumb{
        position: relative;
        margin-bottom: 70px;
        padding: 30px 70px;
        @media #{$mobile_device} {
            padding: 30px;
        }
        img{
            width: 100%;
        }
        .small_img_1{
            width: 182px;
            height: 186px;
            position: absolute;
            top: 0;
            left: 0;
            @media #{$mobile_device} {
                width: 100px;
                height: 100px;
            }
            @media #{$tablet_device} {
                width: 100px;
                height: 100px;
            }
            img{
                width: 100%;
            }
        }
        .small_img_2{
            width: 182px;
            height: 186px;
            position: absolute;
            bottom: 0;
            right: 0;
            @media #{$mobile_device} {
                width: 100px;
                height: 100px;
            }
            @media #{$tablet_device} {
                width: 100px;
                height: 100px;
            }
            img{
                width: 100%;
            }
        }
    }
    .about_text{
        margin-bottom: 20px;
        padding-left: 68px;
        @media #{$mobile_device} {
            padding-left: 0;
        }
        @media #{$tablet_device} {
            padding-left: 0;
        }
        h4{
            font-size: 42px;
            font-weight: 500;
            color: #001D38;
            margin-bottom: 15px;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            color: #4D4D4D;
            line-height: 28px;
            font-family: $font2;
        }
    }
}