
@import 'varriable.scss';
@import 'mixins.scss';
@import 'extend';
@import 'responsive.scss';
@import 'theme-default.scss';
@import 'btn';
@import 'section_title';
@import 'slick-nav.scss';



// header
@import 'header';

// slider
@import 'slider';

// about
@import 'about';

// counter
@import 'counter';
// counter
@import 'team';
// contact_quote
@import 'contact_quote';
// testmonial
@import 'testmonial';
// contact_action
@import 'contact_action';
// _property_details
@import 'property_details';
// accordion
@import 'accordion';
// accordion_area
@import 'accordion_area';
// popular_property
@import 'popular_catagory';
// prise_slider
@import 'prise_slider';
// case_details
@import 'case_details';
// case_details
@import 'description_area';
// case_details
@import 'single_listings';
// case_details
@import '_check';

// gallery
@import '_gallery';
// gallery
@import 'explorer';

// about
@import 'offers';
// about
@import 'footer';

@import 'bradcam';

@import 'department';

@import 'tesmonial';

@import 'service';

@import 'experts';

@import 'emergency_contact';


// other pages default

// contact
@import 'contact';

// elements
@import 'elements.scss';

// blog
@import 'blog';


// blog part
@import 'blog_part';

