.popular_catagory_area{
    padding-top: 150px;
    padding-bottom: 120px;
    @media #{$mobile_device} {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    @media #{$tablet_device} {
        padding-top: 80px;
        padding-bottom: 50px;
    }
    .single_catagory{
        position: relative;
        @include border-radius(10px);
        overflow: hidden;
        margin-bottom: 30px;
        .thumb{
            
            @include border-radius(10px);
            img{
                width: 100%;
                @include border-radius(10px);
            }
        }
        .hover_overlay{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#001d38+0,001d38+100&0+0,1+100 */
            background: -moz-linear-gradient(top,  rgba(0,29,56,0) 0%, rgba(0,29,56,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,29,56,0) 0%,rgba(0,29,56,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,29,56,0) 0%,rgba(0,29,56,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00001d38', endColorstr='#001d38',GradientType=0 ); /* IE6-9 */

            .hover_inner{
                position: absolute;
                left: 20px;
                bottom: 20px;
                h4{
                    font-family: $font2;
                    font-weight: 600;
                    font-size: 18px;
                    color: #fff;
                    margin-bottom: 10px;
                }
                span{
                    font-family: $font2;
                    font-size: 13px;
                    font-weight: 400;
                    color: #FFFFFF;
                    background:rgba(255,255,255,0.2);
                    display: inline-block;
                    padding: 5px 7px;
                    border-radius: 5px;
                    @include transition(.3s);
                }
            }
        }
        &:hover{
            .hover_overlay{
                span{
                    background: #F91842;
                }
            }

        }
    }
}
.sprayed_area{
    background-image: url(../img/banner/sprayed_bg.png);
    padding: 184px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media #{$mobile_device} {
        padding: 100px 0;
    }
    @media #{$tablet_device} {
        padding: 100px 0;
    }
    .text{
        h3{
            font-size: 42px;
            font-weight: 300;
            color: #fff;
            @media #{$mobile_device} {
                font-size: 24px;
            }
            @media #{$tablet_device} {
                font-size: 30px;
            }
        }
        p{
            font-size: 16px;
            color: #fff;
            line-height: 28px;
            font-family: $font2;
            margin-bottom: 42px;
            margin-top: 20px;
        }
    }
}